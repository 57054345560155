<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Role Name
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            :formatter="formatter"
            id="input-name"
            type="text"
            v-model="form.name"
            placeholder="Enter Role Name"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
          ></b-form-input>

          <b-form-invalid-feedback id="input-name-invalid">Please enter Role .</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Permission
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="form.permission"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Permissions"
            label="text"
            track-by="value"
            :options="permission"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
          >
          </multiselect>
        </b-form-group>
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Description
            <span class="text-danger">*</span>
          </template>

          <b-form-textarea
            id="input-name"
            type="text"
            v-model="form.description"
            placeholder="Enter Description If Any"
          ></b-form-textarea>
          <b-form-text id="input-description-help">Description must be more than 1 characters</b-form-text>
          <b-form-invalid-feedback id="input-name-invalid">Please enter Description .</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button type="submit" size="sm" variant="success" :disabled="loading">
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button class="ml-2" size="sm" variant="warning" :to="{ path: `/role` }">
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import Role from '@/model/role';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions, mapState } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import Multiselect from 'vue-multiselect';

export default {
  name: 'RoleFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    roleType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      inputs: [
        {
          name: ''
        },
        {
          description: ''
        }
      ],
      title: '',
      formLoaded: false,
      permission: [],

      form: {
        name: null,
        permission: [],
        description: null
      },
      roleEnabled: _.reduce(
        Role.roleEnabled,
        (result, value, key) => {
          result.push({ value, text: _.capitalize(key) });
          return result;
        },
        []
      )
    };
  },
  validations() {
    const formValidation = {
      name: {
        required
      },
      permission: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.name.required = required;
      formValidation.permission.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.listRole({ router });
    this.listPermission({ router });

    this.$nextTick(async () => {
      if (this.formType === 'new') {
        if (this.roleType === 'role') {
          this.form.id = Role.role.id;
        }
        this.form.status = Role.roleEnabled;
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new Role' : 'Update Role';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('role', ['loading', 'role']),
    ...mapState('permissions', ['permissions'])
  },
  methods: {
    ...mapActions('role', ['listRole', 'getOne', 'postOne', 'patchOne']),
    ...mapActions('permissions', ['listPermission']),

    formatter(name) {
      return name
        .toUpperCase()
        .split(/ /g)
        .join('_');
    },

    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      const permissions = [];
      this.form.permission.map(({ value }) => permissions.push(value));
      const role = {
        name: this.form.name,
        permissions,
        description: this.form.description
      };
      if (this.formType === 'new') {
        this.$emit('add', { role });
      } else {
        this.$emit('edit', { role });
      }
      return false;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    }
  },
  watch: {
    role(newValue) {
      const { id } = newValue;
      if (!id) {
        return;
      }
      // Loaded role, assign to form
      this.form.name = newValue.name;
      this.form.description = newValue.description;
      this.form.permission = newValue.permissions.map(({ name, _id }) => ({ text: name, value: _id }));
      this.formLoaded = true;
      this.$v.$touch();
      this.$v.$reset(); // Reset $dirty
    },
    permissions(newValue) {
      this.permission = newValue;
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
